<template>
	<div>
		<LazyIndexSearchBar v-if="showSearchAndTags"/>
		<LazyIndexTags v-if="showSearchAndTags"/>
		<LazyIndexServerList/>
		<slot/>
	</div>
</template>

<script setup>
const route = useRoute();

const showSearchAndTags = computed(() =>
	route.path === "/" ||
	route.path.startsWith("/rank") ||
	route.path.startsWith("/server-tags") ||
	route.path.startsWith("/search")
);
</script>